import { Component, Input } from '@angular/core';

export interface skillBar{
  name: string,
  pourcentage: number
}
export interface skillBarGroup{
  name: string,
  skills: skillBar[]
}


@Component({
  selector: 'app-bar-skill-group',
  templateUrl: './bar-skill-group.component.html',
  styleUrls: ['./bar-skill-group.component.scss']
})
export class BarSkillGroupComponent {
  @Input()
  skillGroup!: skillBarGroup
}
