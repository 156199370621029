import { Component } from '@angular/core';

export interface Prestation{
  title: string,
  subtitle: string,
  icon: string,
  description: string
}

@Component({
  selector: 'app-prestations',
  templateUrl: './prestations.component.html',
  styleUrls: ['./prestations.component.scss']
})
export class PrestationsComponent {

  prestas: Prestation[] = []

  constructor(){

    this.initPresta();
  }

  initPresta(){
    this.prestas=[
      {
        title: "Site vitrine personnalisé",
        subtitle:"Création, refonte, maintenance",
        description:"",
        icon:"shop.png"
      },
      {
        title: "Référencement",
        subtitle:"Positionnement dans les meilleurs résultats des moteurs de recherches",
        description:"",
        icon:"referencement.png"
      },
      {
        title: "Développement spécifique",
        subtitle:"Création de logiciel, web app, API correspondant a vos besoin",
        description:"",
        icon:"project.png"
      },
      {
        title: "Transformation digital",
        subtitle:"Accompagnement sur la digitalisation de votre entreprise",
        description:"",
        icon:"rocket.png"
      }
    ]
  }
}
