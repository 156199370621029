import { Component } from '@angular/core';
import { ISkills } from '../competence/display-skills/display-skills.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {




}
