import { AfterViewInit, Component } from '@angular/core';
import * as L from 'leaflet';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements AfterViewInit{

  private map!: L.Map;
  private marker!: L.Marker;

  ngAfterViewInit(): void {
    this.initMap();
  }


  private initMap(): void {
    this.map = L.map('map')
    .setView([46.364701, 4.704598], 10);

    //this.map.dragging.disable();
    this.map.options.scrollWheelZoom="center";

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: 'Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors',
      maxZoom: 25
    }).addTo(this.map);

    this.marker = L.marker([46.364701, 4.704598],
      {
        icon: L.icon({
        iconUrl: 'assets/computers.png',
        iconSize: [25, 41],
        iconAnchor: [12.5, 41],
        popupAnchor: [0, -41]
        })
      }).addTo(this.map)


      this.map.on('zoomend', () => {
        this.map.panTo(this.marker.getLatLng());
    });
  }
}
