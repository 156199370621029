import { Component, Inject, Input } from '@angular/core';

export interface ISkills{
  title: string,
  description: string;
}

@Component({
  selector: 'app-display-skills',
  templateUrl: './display-skills.component.html',
  styleUrls: ['./display-skills.component.scss']
})


export class DisplaySkillsComponent {
  @Input() skill!: ISkills



}
