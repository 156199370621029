import { Component } from '@angular/core';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Body, Client } from '../client';
import {MatSnackBar, MatSnackBarModule} from '@angular/material/snack-bar';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  animations: [
    trigger('loadingState', [
      state('loading', style({
        transform: 'translateY(0)' // Définissez ici la position de départ pour le tremblement
      })),
      state('done', style({
        transform: 'translate(100%, -100%)' // Définissez ici la position de départ pour le déplacement diagonal
      })),
      transition('loading => done', animate('500ms ease-out')),
      transition('done => loading', animate('500ms ease-in')),
    ]),
  ],
})

export class ContactComponent {

  contactForm!: FormGroup;
  MAX_LENGTH= 1000;

  isLoading: boolean = false;
  isDone: boolean = false;
  loadingState = 'none';
  sended=false;
  showSecondIcon =false;
  constructor(private formBuilder: FormBuilder,
    private apiClient:Client,
    private snackbar: MatSnackBar
     )
  {
    this.initForm();
  }
  initForm(){
      this.contactForm= this.formBuilder.group(
        {
          name: this.formBuilder.control('', Validators.required),
          email: this.formBuilder.control('',[Validators.required, Validators.email]),
          demande: this.formBuilder.control('',[Validators.required, Validators.maxLength(this.MAX_LENGTH)])
        }
      )
  }

  onSendMailClick(){
    this.loadingState='loading';
    this.isLoading=true;
    this.isDone=false;
    this.apiClient.contact({
      email: this.contactForm.get('email')?.value,
      name: this.contactForm.get('name')?.value,
      message : this.contactForm.get('demande')?.value
    } as Body).subscribe(res => {
          this.snackbar.open('Votre mail a bien été envoyé',undefined, {duration:5000});
          this.isLoading=false;
          this.isDone=true;
          this.loadingState='done';
          this.sended=true;
          setTimeout(() => {
            this.showSecondIcon = true;
          }, 500); // Assurez-vo
    }, err=>{
      this.isLoading=false;
      this.isDone=true;
      this.sended=true;
      setTimeout(() => {
        this.showSecondIcon = true;
      }, 500);
    });
  }
}
