import { Component } from '@angular/core';
import { ISkills } from './display-skills/display-skills.component';

@Component({
  selector: 'app-competence',
  templateUrl: './competence.component.html',
  styleUrls: ['./competence.component.scss']
})
export class CompetenceComponent {
  skills: ISkills[]=[
    {
      title:'Gestion de projet',
      description:'Recueil de besoins, rédaction du cahier des charges/spécification fonctionnel détaillée, suivi du projet, AGILE ou cycle en V'
    },
    {
      title:'Développement',
      description:'Développement de site vitrine, web app, logiciel lourd, en C# .Net, .NetCore, Angular... Amélioration de l\'existant ou création complète à partir de vos besoins'
    },
    {
      title:'Qualité',
      description:'Mise en place d\'un plan de test, création de tests automatisés'
    },
    {
      title:'Responsive',
      description:'Conception UX responsive, multi-support'
    }
  ]
}
