import { Component } from '@angular/core';
import { ISkills } from '../competence/display-skills/display-skills.component';
import * as moment from 'moment';
@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent {
  protected experienceYear:number=0;

  constructor(){
    this.experienceYear = moment().diff(moment([2016,10,5]), 'years');
  }
}
