import { InjectionToken, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { AboutComponent } from './about/about.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { CompetenceComponent } from './competence/competence.component';
import { ContactComponent } from './contact/contact.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DisplaySkillsComponent } from './competence/display-skills/display-skills.component';
import {MatIconModule} from '@angular/material/icon';
import { BarSkillGroupComponent } from './competence/bar-skill-group/bar-skill-group.component';
import { TarifsComponent } from './tarifs/tarifs.component';
import { TarifsCardComponent } from './tarifs/tarifs-card/tarifs-card.component';
import {MatCardModule} from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatButtonModule} from '@angular/material/button';
import { PrestationsComponent } from './prestations/prestations.component';
import { MapComponent } from './contact/map/map.component';
import { HttpClientModule } from '@angular/common/http';
import { Client } from './client';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { BandeauComponent } from './bandeau/bandeau.component';
import { FooterComponent } from './footer/footer.component';

export const API_BASE_URL = new InjectionToken<string>('API_BASE_URL');
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    AboutComponent,
    PortfolioComponent,
    CompetenceComponent,
    ContactComponent,
    DisplaySkillsComponent,
    BarSkillGroupComponent,
    TarifsComponent,
    TarifsCardComponent,
    PrestationsComponent,
    MapComponent,
    BandeauComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FlexLayoutModule,
    MatIconModule,
    MatCardModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    BrowserAnimationsModule,
    MatButtonModule,
    HttpClientModule,
    MatSnackBarModule,
    FormsModule
  ],
  providers: [
    Client,
    { provide: API_BASE_URL, useValue: environment.apiUrl },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
