<div class="title">A propos</div>

<div>
  <p>
    Depuis 2016, mon expertise dans le développement informatique n’a cessé de ce développer.
  </p>
  <p>
    De développeur junior à lead développeur au sein d’une startup lyonnaise pendant 3 ans, en poursuivant mon aventure dans une ESN où plusieurs rôles et missions m’ont été confiés.
  </p>
  <p>
    Mes différentes expériences professionnelles m’ont permis une évolution croissante aussi bien au niveau technique que fonctionnel. À travers différents ateliers : planification de tâches, élaboration de cahier des charges etc.
  </p>
  <p>
    Garantir la satisfaction client est le fil conducteur de mon expérience professionnelle alliant la qualité et la maintenabilité des solutions fournies.
  </p>
</div>
<div fxLayout="row" fxLayoutAlign="center stretch"
 fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutAlign.lt-md="center stretch"
 fxLayoutAlign.gt-sm="center stretch"
  fxLayoutGap="10px">
  <mat-card class="cardAbout" fxFlex="100/3">
    <mat-card-header class="aboutHeader">
      <mat-card-title class="aboutTitle">
        Mon expérience professionnel
      </mat-card-title>
    </mat-card-header>
    <mat-card-content class="cardContent">
      <div class="iconContainer">
        <img class="cardIcon" src="../../assets/pictos/experience.png">
      </div>
      <div>
        Tirant profit de mes différentes expériences professionnelles au cours des {{experienceYear}} dernières années, des divers projets qui ont développé ma polyvalence faisant de l’adaptabilité ma force pour fournir un service de qualité à mes clients.
      </div>
    </mat-card-content>
    </mat-card>
    <mat-card class="cardAbout" fxFlex="100/3">
      <mat-card-header class="aboutHeader">
        <mat-card-title class="aboutTitle">
          Partenaire de proximité
        </mat-card-title>
      </mat-card-header>
      <mat-card-content  class="cardContent">
        <div class="iconContainer">
          <img class="cardIcon" src="../../assets/pictos/handshake.png">
        </div>
        <div>
          Partenaire proche, lors de ma mission les échanges comptent afin de mener au mieux votre projet. Je peux me déplacer dans vos bureaux, mais également échanger en visio ou par téléphone selon votre convenance
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card class="cardAbout" fxFlex="100/3">
        <mat-card-header class="aboutHeader">
          <mat-card-title class="aboutTitle">
            Client unique ⇒ Projet unique
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="cardContent">
          <div class="iconContainer">
            <img class="cardIcon" src="../../assets/pictos/unique.png">
          </div>
          <div>
            Chaque projet est unique, mon objectif est de vous apporter des solutions sur-mesures pour répondre à vos besoins spécifiques.
          </div>
        </mat-card-content>
    </mat-card>
  </div>


