import { Component } from '@angular/core';
import { TarifCard } from './tarifs-card/tarifs-card.component';

@Component({
  selector: 'app-tarifs',
  templateUrl: './tarifs.component.html',
  styleUrls: ['./tarifs.component.scss']
})
export class TarifsComponent {

  tarifs: TarifCard[]=[]
  constructor(){

    this.initTarifs();
  }

  private initTarifs(){
    this.tarifs= [
      {
        title:'Site Vitrine Personnalisé',
        price:1700,
        subtitle:'Création d’un site vitre personnalisé',
        isDailyPrice: false,
        description:[
          'Développement sur mesure',
          'Création de la charte graphique',
          'Responsive ⇒ adapté a tous les support' ,
          'Hébergement + nom de domaine + email + certificat SSL pendant 12 mois',
          'Formulaire de contact, localisation maps…',
          'Interface d’administration complète adapté a vos besoin',
          'Mise en ligne, backup & garantie de fonctionnement pendant 12 mois',
          'Formation utilisation du site, de l’admistration'
        ]
      },
      {
        title:'Développement spécifique',
        price:400,
        subtitle:'Création ou maintenance applicative',
        isDailyPrice: true,
        description:[
          'Rédaction cahier des charges, plan de test, SFD… ',
          'Devis & Tarifs adapté au besoin, a la complexité et au temps de travail',
          'Utilisation des languages de programmation C#, TS, JS…' ,
          'Environnement de travail: .Net, Angular, NodeJs, PowerAutomate, PowerApps'
        ]
      }
    ]
  }
}
