<div class="title">Compétences</div>
<div fxLayout.gt-sm="row" fxLayout.lt-md="column"
fxLayoutAlign="center center"
fxLayoutGap="10px">
    <div fxLayout="column" fxLayoutAlign="center stretch" class="competenceContainer" fxLayoutGap="10px">
      <ng-container *ngFor="let skill of skills">
        <app-display-skills [skill]="skill"></app-display-skills>
      </ng-container>
    </div>
    <div fxLayoutAlign="center center">
      <img src="../../assets/Logos.png" class="imgLogo">
    </div>
 </div>
