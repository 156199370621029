import { Component } from '@angular/core';

@Component({
  selector: 'app-mention-legal',
  standalone: true,
  imports: [],
  templateUrl: './mention-legal.component.html',
  styleUrls: ['./mention-legal.component.scss']
})
export class MentionLegalComponent {

}
