<mat-card class="tarifCard">
  <mat-card-header>
    <div fxLayout="column" fxLayoutAlign="center center" style="width:100%; text-align: center;">
      <span class="tarifTitle" style="width:100%">{{tarif?.title}}</span>
      <div style="width:100%" class="pricing">
        <span>A partir de </span>
        <span>{{tarif?.price}}€</span><sup *ngIf="tarif?.isDailyPrice" class="daily">/jour</sup>
      </div>
    </div>
  </mat-card-header>
  <mat-card-content class="tarifContent">
    <div>{{tarif?.subtitle}} </div>
      <div *ngFor="let subs of tarif?.description" class="descriptionContainer">
        <mat-icon fontIcon="chevron_right"></mat-icon>
        <span>{{subs}}</span>
    </div>

  </mat-card-content>
</mat-card>
