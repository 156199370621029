<div class="title">Contact</div>

<div
fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutAlign.lt-md="center stretch" fxLayoutAlign.gt-sm="center stretch"
      fxLayoutGap="10px">
  <div fxFlex="50" fxLayout="column" fxLayoutGap="10px">
      <div class="contactline">
        <mat-icon svgIcon="call"></mat-icon> <a href="tel:0766044734">07.66.04.47.34</a>
      </div>
      <div class="contactline">
        <mat-icon svgIcon="email"></mat-icon> <a href="mailto:bds-dev@outlook.com">bds-dev@outlook.com</a>
      </div>
      <div class="contactline" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon svgIcon="calendar"></mat-icon>
        <div fxLayout="column">
          <span>Lundi - Vendredi</span>
          <span>08h00 - 18h00</span>
        </div>
      </div>
      <div class="contactline">
        <mat-icon svgIcon="siret"></mat-icon> 924777386
      </div>
      <app-map style="width: 100%; height: 100%;"></app-map>
  </div>
  <div fxFlex="50" fxLayoutGap="0px">
  <form [formGroup]="contactForm" (ngSubmit)="onSendMailClick()">
    <div fxLayout="column">
      <mat-form-field>
        <mat-label>Nom, prénom, société</mat-label>
        <input matInput formControlName="name">
      </mat-form-field>

      <mat-form-field>
        <mat-label>Email</mat-label>
        <input matInput formControlName="email">
      </mat-form-field>

      <mat-form-field>
        <textarea class="demandeTxt" matInput formControlName="demande" placeholder="Merci de fournir le plus d’informations possible sur votre besoin afin de faciliter nos futurs échanges."></textarea>
      </mat-form-field>

      <button type="submit" class="button-send"
      [ngClass]="{'loading': isLoading && !isDone, 'ready': isDone}"
      mat-raised-button color="primary"  [disabled]="!contactForm.valid">
        Envoyer
        <img src="../../assets/pictos/plane.svg" id="plane"  [ngClass]="{'animation': (isLoading && !isDone || isDone), 'noneanim': isDone, 'none':showSecondIcon }" width="32px" height="32px">
        <img src="../../assets/pictos/check.svg" id="bg"  [ngClass]="{'none': !showSecondIcon, 'animation2': showSecondIcon}" width="32px" height="32px">
        </button>
    </div>
  </form>
  </div>
</div>
