<div class="stickyHead" fxLayout="column">

  <div class="stickyTitle" fxLayoutAlign="center center" fxLayout="row">
    <div fxFlex="15">
      <img class="imgLogo" src="../../assets/logo_portfolio_min.png">
    </div>

    <div class="categContainer" fxLayoutAlign="space-between start">
    <ul class="container">

      <li class="boxes" (click)="onClickScroll('about')" >A propos</li>
      <li class="boxes" (click)="onClickScroll('prestations')" >Services</li>
      <li class="boxes" (click)="onClickScroll('competence')">Compétences</li>
      <li class="boxes" (click)="onClickScroll('contact')">Contact</li>
    </ul>
    </div>


    <div fxFlex="8" fxLayoutAlign="end center" (click)="openCategMenu()" class="menuCheck">
      <img src="../../assets/pictos/menu.svg">
    </div>
  </div>
  <div  class="stickyCateg" [ngClass]="{'show': isMenuOpen}">
    <div fxLayoutAlign="space-between center">
<ul class="container">
  <li class="boxes" (click)="onClickScroll('about')">A propos</li>
  <li class="boxes" (click)="onClickScroll('prestations')" >Services</li>
  <li class="boxes" (click)="onClickScroll('competence')">Compétences</li>
  <li class="boxes" (click)="onClickScroll('contact')">Contact</li>
</ul>
</div>
  </div>


</div>



