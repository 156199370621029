<app-bandeau></app-bandeau>
<app-header></app-header>
<div fxLayout="row" class="homepage">
  <div fxFlex.gt-sm="20"></div>
  <div fxFlex.gt-sm="60">
    <app-about id="about" class="section"></app-about>
    <app-prestations id="prestations" class="section"></app-prestations>
    <app-competence id="competence" class="section"></app-competence>
    <app-contact id="contact" class="section"></app-contact>
  </div>
  <div fxFlex.gt-sm="20"></div>
</div>
<app-footer></app-footer>
