<div class="container">
    <div class="head" >
        <a href="./"><img class="arrow" src="../../assets/pictos/previous-arrow.svg"></a>
        <h1>Mentions Légales</h1>
    </div>

    <h2>Propriétaire du site :</h2>
    <p>
        Nom : Baptiste Desbois <br>
        Nom commercial : Bdsdev <br>
        Adresse : 2 rue de Bourgogne, 71960 Berzé-la-Ville <br>
        E-mail : <a href="mailto:bds-dev@outlook.com">bds-dev@outlook.com</a> <br>
        Téléphone : <a href="tel:+33766044734">07 66 04 47 34</a> <br>
        Statut juridique : Auto-entrepreneur <br>
        Numéro SIREN : 924 777 386 <br>
        Directeur de publication : Baptiste Desbois
    </p>

    <h2>Hébergeur du site :</h2>
    <p>
        Nom de l'hébergeur : IONOS <br>
        Adresse de l'hébergeur : 7 place de la Gare, BP 70109, 57200 Sarreguemines Cedex <br>
        Site web de l'hébergeur : <a href="https://www.ionos.fr" target="_blank">www.ionos.fr</a>
    </p>

    <h2>Conditions d'utilisation :</h2>
    <p>
        L'utilisation du site <strong>bdsdev.fr</strong> implique l'acceptation pleine et entière des conditions générales d'utilisation décrites ci-après. Ces conditions d'utilisation sont susceptibles d'être modifiées ou complétées à tout moment, les utilisateurs du site <strong>bdsdev.fr</strong> sont donc invités à les consulter de manière régulière.
    </p>

    <h2>Propriété intellectuelle :</h2>
    <p>
        Le site <strong>bdsdev.fr</strong> ainsi que l'ensemble de ses contenus (textes, images, graphismes, logo, etc.) sont protégés par les dispositions du Code de la propriété intellectuelle. Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de Baptiste Desbois.
    </p>
    <p>
        Toute exploitation non autorisée du site ou de l'un quelconque de ses éléments sera considérée comme constitutive d'une contrefaçon et poursuivie conformément aux dispositions des articles L.335-2 et suivants du Code de la propriété intellectuelle.
    </p>

    <h2>Protection des données personnelles :</h2>
    <p>
        Les informations recueillies via le formulaire de contact du site <strong>bdsdev.fr</strong> (nom, e-mail, message) sont envoyées par e-mail à l'adresse <strong>bds-dev@outlook.com</strong> dans le cadre des échanges nécessaires à la gestion de vos demandes. Ces données ne sont utilisées que dans ce cadre et ne sont pas transmises à des tiers.
    </p>
    <p>
        Conformément à la loi "Informatique et Libertés" du 6 janvier 1978 modifiée et au Règlement Général sur la Protection des Données (RGPD), vous disposez d’un droit d’accès, de rectification, de suppression et d’opposition concernant vos données personnelles. Pour exercer ces droits, vous pouvez contacter : <a href="mailto:bds-dev@outlook.com">bds-dev@outlook.com</a>.
    </p>

    <h2>Cookies :</h2>
    <p>
        Le site <strong>bdsdev.fr</strong> utilise des cookies uniquement à des fins de fonctionnement et d’optimisation du site. Aucun cookie de suivi ou à des fins publicitaires n’est utilisé. Vous pouvez désactiver les cookies en modifiant les paramètres de votre navigateur.
    </p>

    <h2>Limitation de responsabilité :</h2>
    <p>
        Le propriétaire du site ne pourra être tenu responsable des dommages matériels liés à l’utilisation du site. De plus, l'utilisateur s'engage à accéder au site en utilisant un matériel récent, ne contenant pas de virus et avec un navigateur de dernière génération mis à jour.
    </p>

    <p>
        Ces mentions légales sont susceptibles d’être modifiées à tout moment, notamment en cas de changements législatifs ou réglementaires. Nous vous invitons à les consulter régulièrement.
    </p>
</div>