import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'portfolio';

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer){

    this.matIconRegistry.addSvgIcon(
      `siret`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/pictos/siret.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `calendar`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/pictos/calendar.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `email`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/pictos/email.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `call`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/pictos/telephone.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `checked`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/pictos/checked.svg")
    );

  }

}
