<div class="title">Services</div>

<div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutAlign.lt-md="center stretch" fxLayoutAlign.gt-sm="center stretch"
      fxLayoutGap="10px">
    <mat-card *ngFor="let presta of prestas" fxFlex="25" class="vitre-card">
      <mat-card-title class="custom-card-title">
        {{presta.title}}
      </mat-card-title>
      <mat-card-content class="card-content" fxLayout="column" fxLayoutAlign="center center" fxFlexAlign="center">
        <div class="iconContainer">
          <img class="iconPresta" src="./assets/pictos/{{presta.icon}}" alt="">
        </div>
        <div class="subtitleContainer">
          <span>{{presta.subtitle}}</span>
        </div>
      </mat-card-content>
    </mat-card>
</div>
