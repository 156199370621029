import { NgModule, Component } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { MentionLegalComponent } from './mention-legal/mention-legal.component';

const routes: Routes = [
  //{path: '**', redirectTo: '', pathMatch: 'full' },
  { path: '', component: HomeComponent },
  { path: 'mentions-legales', component: MentionLegalComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    useHash:false,
    anchorScrolling:'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
