import { Component, Input } from '@angular/core';

export interface TarifCard{
  title: string,
  price: number,
  isDailyPrice: boolean,
  subtitle: string,
  description: string[]
}


@Component({
  selector: 'app-tarifs-card',
  templateUrl: './tarifs-card.component.html',
  styleUrls: ['./tarifs-card.component.scss']
})
export class TarifsCardComponent {

  @Input()
  tarif?: TarifCard

}
